// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import { ChangePasswordModel, UserLoggedInfoModel } from "@/models/userModels";
import { mapActions } from "vuex";
import baseContextComponent from "@/components/baseContextComponent";
import api from "@/services/api";
import apiFile from '@/services/apiFile';

@Component({
})
export default class adesioniDownalod extends baseContextComponent {
	isLoading: boolean = false;

	created() {
		var instance = this;
		var idAdesione = this.$route.query.id as string;
		instance.isLoading = true;
		apiFile.downloadAdesione(idAdesione)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/pdf" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = "Certificato Adesione.pdf";
				link.click();
				URL.revokeObjectURL(link.href);
			}).finally(() => instance.isLoading = false);
	}
}
